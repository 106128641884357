import React, { Component } from 'react';
import { doctorContent } from '../../global/contentSelectors/doctorsSelector';
import './DoctorDescription.scss';

export default class DoctorDescription extends Component {

  getDoctorCVLink = () => {
    let doctorId = this.props.doctorId;
    //window.location.href = '/data/' + doctorId + '.pdf';
    return '/data/' + doctorId + '.pdf';
  };

  render() {

    const { doctorId } = this.props;

    return (
      <div className="specialty-description module-layout">

        {/* title and description */}
        { doctorContent( doctorId ).description }

        <div style={{textAlign:"right"}} className="_margin-top-80">
          <a className="button_color" href={this.getDoctorCVLink()} target="_blank">Bajar perfíl</a>
        </div>

      </div>
    )
  }
}
