import React, { Component } from 'react';

export default class Tpadres extends Component {

  render() {
    return (
      <div style={{textAlign: 'justify'}}>
        {/*<h2 className="headline _uppercase">Escuela para padres Psicología</h2>*/}
        <p>En las escuelas para padres se trabajan aquellas temáticas que son frecuentes en el motivo de consulta en la terapia  y temas de la actualidad de interés para las familias en Costa Rica.</p>
        <p>El objetivo de estas escuelas es ayudar a los padres y madres en la crianza de los hijos y orientarlos en las diferentes etapas del desarrollo por las que atraviesan los niños (as), las cuales, en muchas ocasiones son motivo de angustia y temor para los padres.</p>
        <p>A la vez se desarrollan temas como la crianza positiva, como comunicarse con los adolescentes, fomentar el sentido de responsabilidad, límites, uso de redes sociales, sexualidad en las diferentes etapas,  entre otros.</p>
      </div>
    )
  }
}
