import React, {Component} from 'react';
import Modal from 'react-modal';
import LogoNous from "../LogoNous/LogoNous";
import './ModalWindow.scss';

Modal.setAppElement('#root');
export default class ModalWindow extends Component {

  constructor(props) {
    super(props);

    this.state = {
      fullName: '',
      phone: '',
      email: '',
      userQuestion: '',
      showModal: false
    };

    this.baseState = this.state;
  };

  componentDidMount() {

    this.props.setClick( this.openModal );
  };

  /**
   * openModal
   */
  openModal = () => {
    this.setState({showModal: true});
  };
  /**
   * closeModal
   */
  closeModal = () => {

    this.setState({showModal: false});
  };

  render() {

    const { modalcontent } = this.props;

    return (
      <div>
        <div className="modal-window">
          {/*MODAL:INIT*/}
          <Modal isOpen={this.state.showModal} className="modalCss" overlayClassName="overlayCss">

            <LogoNous/>

            <button type="button" onClick={this.closeModal} className="button_close">X</button>

            {modalcontent}

          </Modal>
          {/*MODAL:END*/}
        </div>
      </div>
    )
  }
}
