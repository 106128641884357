import React, { Component } from 'react';

export default class Tjovenes extends Component {

  render() {
    return (
      <div style={{textAlign: 'justify'}}>
        {/*<h2 className="headline _uppercase">Terapia para niños y adolescentes Psicología</h2>*/}
        <p>En la terapia psicológica con niños se trabaja no solo con el o la menor, si no que con los encargados y familiares si fuese necesario y en muchas ocasiones con las instituciones educativas a las cuales asisten.</p>
        <p>En este tipo de terapia se trabajan tres demandas importantes: la de los encargados, la de la institución y la del menor, en la terapia se trabaja el origen de la conducta, buscando un mejoramiento de esa conducta y cambiar lo que la está originando.</p>
        <p>Dentro de las temáticas que más se consultan están: manejo del enojo, sentido de responsabilidad, manejo de límites, bullying, trastorno del desarrollo, crisis emocionales, expresión y manejo de las emociones, dificultades escolares, relaciones fraternales, divorcio de los padres, sexualidad según la etapa del desarrollo, vínculo con sus pares, entre otros.</p>
      </div>
    )
  }
}
