import React, { Component } from 'react';

import './LogoNous.scss';
import logonous from '../../assets/img/logo-naia.jpg';

export default class LogoNous extends Component {

  render() {
    return (
      <div className='logo-nous'>
          <div className='logo-holder'>
            <img src={logonous} alt="Naia, Clínica de psicología y psicoterapia" />
          </div>
          <div>
            <h1>Naia</h1>
            <p><span className="_uppercase" style={{color:'#FFF'}}>Clínica de psicología y psicoterapia</span></p>
          </div>
      </div>
    )
  }
}
