import React, {Component} from 'react';
import {moveThePageTo} from '../../global/_utilities';

// template
import HeaderFooter from "../../templates/HeaderFooter/HeaderFooter";

// modules
import SpecialtyHeader from "../../components/SpecialtyHeader/SpecialtyHeader";
import QuienesSomos from "../../components/QuienesSomos/QuienesSomos";
import MeetOurDoctors from "../../components/MeetOurDoctors/MeetOurDoctors";
import SpecialtyContent from "../../components/SpecialtyContent/SpecialtyContent";
import BandAnnounce from "../../components/BandAnnounce/BandAnnounce";
import BandAnnounceB from "../../components/BandAnnounceB/BandAnnounceB";
import QuoteNote from "../../components/QuoteNote/QuoteNote";

export default class Home extends Component {

    componentDidMount() {

        // URL params
        const {match: {params}} = this.props;

        // Default description content
        let deep = params.deep;

        moveThePageTo(deep);
    }

    render() {

        let pageContent = (
            <React.Fragment>

                <BandAnnounceB />

                <div className="content">
                    <div className="content_margin">

                        <SpecialtyHeader/>
                        <SpecialtyContent/>

                    </div>
                </div>

                <QuoteNote/>

                <div className="content" id="quienessomos">
                    <div className="content_margin">

                        <QuienesSomos/>

                    </div>
                </div>

                <BandAnnounce/>

                <div className="content">

                    <MeetOurDoctors/>

                </div>

                {/*<GoogleMap />*/}

            </React.Fragment>
        );

        return (
            <div>
                <HeaderFooter pageId='home' heroSection='home' pageContent={pageContent}/>
            </div>
        )
    }
}
