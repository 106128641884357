import React, { Component } from 'react';
import $ from 'jquery';

import './HeaderFooter.scss';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDoubleUp} from "@fortawesome/free-solid-svg-icons";

export default class HeaderFooter extends Component {

  componentDidMount() {

    this.data = {
      $sendUp: $('.sendUp')
    };

    this.data.$sendUp.on('click', (evt)=>{
      evt.preventDefault();
      $('html, body').animate({ scrollTop: 0 }, 600);
    });

    $(document).on('scroll', (data)=>{
      let dH = $(document).height();
      let dS = $(document).scrollTop();
      let percent = Math.round((100*dS)/dH);

      if(percent > 76) {
        if(!this.data.$sendUp.hasClass()){
          this.data.$sendUp.addClass('active');
        }
      } else {
          this.data.$sendUp.removeClass('active');
      }
    });
  }

  render() {

    const { heroSection, pageContent, area, doctorId } = this.props;

    return (
      <div className="header-footer">
        <div className="content-wrap">
          <Header className="header" heroSection={ heroSection } area={ area } doctorId ={ doctorId } />

          {pageContent}

        </div>

        <div className="sendUp _box-shadow"><FontAwesomeIcon icon={faAngleDoubleUp} className="color_white _margin-right-10"/>inicio</div>

        <Footer className="Footer" />
      </div>
    )
  }
}
