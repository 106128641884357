import React from 'react';
import Routes from "./Routes";
import { BrowserRouter as Router } from 'react-router-dom';

import ResetTop from './global/ResetTop';

function App() {
  return (
    <Router>
      <ResetTop>
        <Routes />
      </ResetTop>
    </Router>
  );
}

export default App;
