import React, {Component} from 'react';

import './BandAnnounceB.scss';

import ModalWindow from '../ModalWindow/ModalWindow';
import SendContact from '../forms/SendContact/SendContact';

export default class BandAnnounceB extends Component {
  render() {

    // MODAL FORM
    const modalcontent = (
      <SendContact />
    );

    return (
      <div className="band-announce-b module-layout">
        <div className="content">
          <div className="content_margin">

            <h2 className='color_white _uppercase'>Psicología en línea</h2>

            <div className="row">
              <div className="col-md-6">
                <div>
                  <h3 className='color_white _uppercase'>Llámanos:</h3>
                  <p className='color_white'>+506-4040-0185</p>
                </div>
              </div>
              <div className="col-md-6">
                <h3 className='color_white _uppercase'>Escríbenos:</h3>
                <p className='color_white'>
                  <button onClick={ () => this.clickChild() } className="button_over">contactenos@clinicanaia.com</button>
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* MODAL : INIT */}
        <ModalWindow modalcontent={ modalcontent } setClick={ click => this.clickChild = click }/>
        {/* MODAL : END */}

      </div>
    )
  }
}
