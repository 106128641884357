import React, { Component } from 'react';
import './SendConsult.scss';
const axios = require('axios');

export default class SendConsult extends Component {

  constructor (props){
    super(props);

    this.state = {
      fullName: '',
      phone: '',
      email: '',
      userQuestion: '',
      terapia: '',
      emailWasSent: null,
      emailProcess: false
    };

    this.basestate = this.state;
  }

  /**
   * passesRegex
   * @param email
   * @returns {boolean}
   */
  passesRegex = email => {
    return /[-0-9a-zA-Z.+_]+@[-0-9a-zA-Z.+_]+\.[a-zA-Z]{2,4}/.test(email);
  };

  /**
   * sendEmail
   * @param evt
   */
  sendEmail = () => {
    const {fullName, phone, email, userQuestion} = this.state;
    const terapia = this.props.area;
    const endpoint = '/mail/emailTerapiaConsulta.php';
    const formData = new FormData();
    formData.append('fullName', fullName);
    formData.append('phone', phone);
    formData.append('email', email);
    formData.append('userQuestion', userQuestion);
    formData.append('terapia', terapia);

    axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
    axios.post(endpoint, formData)
      .then((response) => {

        console.warn("Email sent");

        this.setState({
          emailProcess: true,
          emailWasSent : true
        });

      })
      .catch((err) => {

        this.setState({
          emailProcess: true,
          emailWasSent : false
        });
      });

    // Form reset
    this.setState(this.basestate);
  };

  /**
   * onInputUpdate
   * @param evt
   */
  onInputUpdate = (evt) => {

    let name = evt.target.name;
    let value = evt.target.value;

    this.setState({
      [name]:value
    })
  };

  /**
   * onSubmit
   * @param evt
   */
  onSubmit = (evt) => {
    evt.preventDefault();
    //
    const { email } = this.state;

    // validation
    if (!this.passesRegex(email)) {

      console.error("Email is not valid");
      return;
    }

    // validation pass
    this.sendEmail();

    // reset the form
    this.setState(this.baseState);
  };

  render() {

    const { emailProcess, emailWasSent } = this.state;

    return (
      <div className="send-consult">

        {
          emailProcess ?

            emailWasSent ? (
              <div className="email-sent">
                <p className=""><strong>Tu correo ha sido enviado.</strong></p>
                <p>Nuestros Psicólogos te escribirán a tu correo antes de llamar.</p>
              </div>
            ) : (
              <div className="email-sent">
                  <p className=""><strong>Tu correo NO ha podido ser enviado.</strong></p>
                  <p>Te pedimos disculpas por el inconveniente !!!.</p>
                  <p>Llámanos : +506-4040-0185</p>
              </div>
            )

            : false
        }

        <form onSubmit={this.onSubmit}>

          <label data-error="wrong" data-success="right" htmlFor="defaultForm-email">Nombre completo</label>
          <input type="text" className="form-control" onChange={this.onInputUpdate} value={this.state.fullName}
                 name="fullName"/>

          <label data-error="wrong" data-success="right" htmlFor="defaultForm-email">Teléfono</label>
          <input type="number" className="form-control" onChange={this.onInputUpdate} value={this.state.phone}
                 name="phone"/>

          <label data-error="wrong" data-success="right" htmlFor="defaultForm-email">Correo Electrónico</label>
          <input type="email" className="form-control" onChange={this.onInputUpdate} value={this.state.email}
                 name="email"/>

          <label data-error="wrong" data-success="right" htmlFor="defaultForm-email">Tu consulta</label>
          <textarea className="form-control" onChange={this.onInputUpdate} value={this.state.userQuestion}
                 name="userQuestion"/>

          <button className="button_color" type="Submit">enviar</button>

          <div className="note">
            <h3>Politicas de privacidad.</h3>
            <p>Los datos enviados en este formulario son absolutamente de uso interno, atendidos por nuestros Psicólogos y respondidos por los mismos.</p>
            <p>Bajo ninguna circunstancia terceros a la clínica psicológica Naia, tiene accesso a las consultas de nuestros clientes o pacientes.</p>
          </div>

        </form>
      </div>
    )
  }
}
