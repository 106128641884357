import React, { Component } from 'react';

export default class Tdefault extends Component {

  render() {
    return (
      <div>
        <h2 className="headline _uppercase">Terapias</h2>
        <p>.....</p>
      </div>
    )
  }
}
