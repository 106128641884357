import React, { Component } from 'react';

export default class Tcoaching extends Component {

  render() {
    return (
      <div style={{textAlign: 'justify'}}>
        {/*<h2 className="headline _uppercase">Coaching</h2>*/}
        <p>El coaching es un método de intervención especializada que gana terreno en Costa Rica y consiste en acompañar, a una persona o a un grupo de ellas, con el fin de conseguir cumplir metas o desarrollar habilidades específicas.</p>
        <p>Durante el proceso de coaching, el cliente profundiza en su autoconocimiento, aumenta su rendimiento y mejora su calidad de vida, produciendo resultados extraordinarios en sus competencias personales y organizacionales.</p>
        <p>La intervención se da a través de un espacio de conversación entre dos personas el "Coach" y el "Coachee" (cliente), que facilita el paso de: "la situación actual" a "la situación deseada". </p>
        <p>El coaching se basa en el respeto a la otra persona, dejando espacio para que sea: "como es".</p>
        <p>El coaching promueve movimiento y cambio, pasar de las palabras a la ACCION, diferencia lo que HACEMOS de lo que SOMOS.</p>
      </div>
    )
  }
}
