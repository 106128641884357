import React, { Component } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMobileAlt} from "@fortawesome/free-solid-svg-icons";

import SendToDoctor from "../../forms/SendToDoctor/SendToDoctor";
import ModalWindow from "../../ModalWindow/ModalWindow";

export default class Dmarisol extends Component {

  constructor(props){
    super(props);

    this.state = {
      doctorEmail:''
    }
  }

  render() {

    const { doctorEmail } = this.state;

    // MODAL FORM
    const modalcontent = (
      <SendToDoctor doctorEmail = {doctorEmail} />
    );

    return (
      <div style={{textAlign: 'justify'}}>
        <h2 className="headline _uppercase">Psicología clínica en niños, adolescentes y adultos terapia individual y pareja.</h2>
        <p>La Dra. Montero, se interesa por orientar principalmente el desarrollo de las personas, la resolución efectiva de conflictos y el mejoramiento de la calidad de vida de personas paralelo a sus relaciones intra e inter familiares.</p>
        <p>En el ámbito infantil, se interesa por la salud mental y emocional de los menores, así como de generar vínculos sanos en las familias.</p>

        <div className="card-info">
          <h3>Contácto</h3>

          <ul className="ul-doctor-info">
            <li>
              <button onClick={ () => {

                this.setState({
                  doctorEmail:'mmontero@clinicanaia.com'
                });

                this.clickChild();
              }
              }>mmontero@clinicanaia.com</button>
            </li>

            <li className="p-inline">
              <p><FontAwesomeIcon icon={faMobileAlt} className="color_black _margin-right-10"/>Cel 8326-9782</p>
              <p><FontAwesomeIcon icon={faMobileAlt} className="color_black _margin-right-10"/>Ofic 4040-0185</p>
            </li>

          </ul>
        </div>

        {/* MODAL : INIT */}
        <ModalWindow modalcontent={ modalcontent } setClick={ click => this.clickChild = click }/>
        {/* MODAL : END */}

      </div>
    )
  }
}
