import React, {Component} from 'react';

import './BandAnnounce.scss';

import ModalWindow from '../ModalWindow/ModalWindow';
import SendContact from '../forms/SendContact/SendContact';

export default class BandAnnounce extends Component {
  render() {

    // MODAL FORM
    const modalcontent = (
      <SendContact />
    );

    return (
      <div className="band-announce module-layout">
        <div className="content">
          <div className="content_margin">
            <div className="row">
              <div className="col-md-6">
                <div>
                  <h2 className='color_white _uppercase'>Llámanos:</h2>
                  <p className='color_white'>+506-4040-0185</p>
                </div>
              </div>
              <div className="col-md-6 _margin-top-md-50">
                <h2 className='color_white _uppercase'>Escríbenos:</h2>
                <p className='color_white'>
                  <button onClick={ () => this.clickChild() } className="button_over">contactenos@clinicanaia.com</button>
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* MODAL : INIT */}
        <ModalWindow modalcontent={ modalcontent } setClick={ click => this.clickChild = click }/>
        {/* MODAL : END */}

      </div>
    )
  }
}
