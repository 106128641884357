import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import './Footer.scss';
import LogoNous from "../LogoNous/LogoNous";
import BandPhoneEmail from "../BandPhoneEmail/BandPhoneEmail";
import ModalWindow from '../ModalWindow/ModalWindow';
import SendContact from '../forms/SendContact/SendContact';

export default class Footer extends Component {
  render() {

    // MODAL FORM
    const modalcontent = (
      <SendContact />
    );

    return (
      <footer className="footer">

        <div className="content">
          <div className="content_margin _margin-bottom-30 _margin-top-30">
            <div className="row">

              <div className="col-sm-12 col-md-6">
                <div className="row">
                  <h3 className="_margin-bottom-10">Especialidades:</h3>
                  <div className="col-sm-7">
                    <ul className="speciality-ul">
                      <li><Link className="a_over" to='/speciality/t.individual'>Terapia Individual</Link></li>
                      <li><Link className="a_over" to='/speciality/t.pareja' replace>Terapia en Pareja</Link></li>
                      <li><Link className="a_over" to='/speciality/t.jovenes' replace>Terapia Niños y Adolecentes</Link></li>
                      <li><Link className="a_over" to='/speciality/t.familiar' replace>Terapia Familiar</Link></li>
                      <li><Link className="a_over" to='/speciality/t.adultomayor' replace>Terapia Adulto Mayor</Link></li>
                      <li><Link className="a_over" to='/speciality/t.padres' replace>Escuela para Padres</Link></li>
                      <li><Link className="a_over" to='/speciality/t.gruposapoyo' replace>Grupos de Apoyo</Link></li>
                      <li><Link className="a_over" to='/speciality/t.coaching' replace>Coaching</Link></li>
                    </ul>
                  </div>
                  <div className="col-sm-5">
                    <ul className="speciality-ul">
                      <li><a href="/home/quienessomos" target='_self'>Misión</a> </li>
                      <li><a href="/home/quienessomos" target='_self'>Visión</a> </li>
                      <li><a href="#/" target='_self' onClick={ () => this.clickChild() }>Contáctenos</a> </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 _margin-top-md-30 _margin-top-sm-30">

                <a href="/" target="_self" style={{textDecoration:'none'}}>
                  <LogoNous/>
                  {/*<h1 className=" footerLogo">NAIA Psicólogos</h1>*/}
                </a>

                <div className="color_white _margin-top-20 _margin-bottom-md-20 _margin-top-md-20">
                  <p className="sigma _margin-bottom-5">SIGMA BUSINESS CENTER</p>
                  <p>San José, Montes de Oca, San Pedro, Edificio SIGMA, Torre A, Piso 2, Republic, Work.</p>
                </div>

              </div>
            </div>
          </div>
        </div>

        <BandPhoneEmail isBottom={true}/>

        {/* MODAL : INIT */}
        <ModalWindow modalcontent={ modalcontent } setClick={ click => this.clickChild = click }/>
        {/* MODAL : END */}

      </footer>
    )
  }
}
