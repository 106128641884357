import React, { Component } from 'react';

import './QuienesSomos.scss';

export default class QuienesSomos extends Component {
  render() {
    return (
      <div className='quienes-somos module-layout'>
        <h2 className="headline _uppercase">Quienes Somos</h2>
        <p>Somos una clínica ubicada en San José, Costa Rica, nuestra especialidad es la psicología en niños, adultos y adultos mayores, orientados a satisfacer las necesidades de nuestros pacientes, ofreciendo soluciones personalizadas que les ayuden a afrontar los problemas que los afectan.</p>
        <p>En la Clínica Psicológica Naia - Costa Rica, se brinda psicoterapia y una serie de talleres y charlas a diferentes poblaciones, con el objetivo de dar respuestas a las necesidades específicas de la niñez, adolescencia y adultez, así como grupos de apoyo y manejo laboral.</p>
        <p>Brindamos los recursos necesarios a las personas, para que puedan tener la capacidad de resolver las situaciones del diario vivir de una manera eficiente, guiados por profesionales con alto conocimiento en psicología  desde diferentes áreas y con un excelente manejo de los temas de actualidad.</p>
        <p>Adicionalmente,  se brinda un espacio para reflexionar sobre las dinámicas familiares y las situaciones cotidianas que se presentan y de esta manera llegar a una conclusión que favorezca el desarrollo de las habilidades personales de cada miembro y lleguen a resolver los problemas y solventar las necesidades de sus miembros.</p>

        <h3 className="sub-headline _uppercase mision">Misión</h3>
        <p>Somos una Clínica especializada en psicología, enfocada en ofrecer servicios de salud de calidad. Gracias a la experiencia y al trabajo diario con nuestros pacientes, generando y potenciando la salud mental a lo largo de los ciclos vitales: infancia, adolescencia, adultez y vejez.</p>
        <p>Promoviendo estilos de vida saludables, mediante escuelas para padres, terapia individual, terapia de pareja, terapia familiar, para niños y adultos y coaching, además apoyamos a las organizaciones en el campo laboral, orientados a desarrollar competencias en las personas que les permitan crecer y alcanzar su máximo potencial.</p>

        <h4 className="sub-headline _uppercase vision">Visión</h4>
        <p>Enriquecer la vida de las personas mediante la terapia psicológica, ganándonos la admiración de nuestros pacientes, nuestros colaboradores y nuestros grupos de interés en Costa Rica, porque somos personas socialmente responsables con una organización con capacidades y compromisos superiores en psicología, no solo por los objetivos que logramos, sino por la forma en que los alcanzamos.</p>


        {/*<div className="row _margin-top-40">*/}
        {/*  <div className="col-sm-12 col-md-6">*/}
        {/*    <h3 className="sub-headline _uppercase">Misión</h3>*/}
        {/*    <p>Somos una Clínica especializada en psicología, enfocada en ofrecer servicios de salud de calidad. Gracias a la experiencia y al trabajo diario con nuestros pacientes, generando y potenciando la salud mental a lo largo de los ciclos vitales: infancia, adolescencia, adultez y vejez.</p>*/}
        {/*    <p>Promoviendo estilos de vida saludables, mediante escuelas para padres, terapia individual, terapia de pareja, terapia familiar, para niños y adultos y coaching, además apoyamos a las organizaciones en el campo laboral, orientados a desarrollar competencias en las personas que les permitan crecer y alcanzar su máximo potencial.</p>*/}
        {/*  </div>*/}
        {/*  <div className="col-sm-12 col-md-6">*/}
        {/*    <h4 className="sub-headline _uppercase">Visión</h4>*/}
        {/*    <p>Enriquecer la vida de las personas mediante la terapia psicológica, ganándonos la admiración de nuestros pacientes, nuestros colaboradores y nuestros grupos de interés en Costa Rica, porque somos personas socialmente responsables con una organización con capacidades y compromisos superiores en psicología, no solo por los objetivos que logramos, sino por la forma en que los alcanzamos.</p>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    )
  }
}
