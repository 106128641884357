import React from "react";

// Hero assets
import heroTerapiaIndividual from '../../assets/img/hero-terapia-individual.jpg';
import heroTerapiaPareja from '../../assets/img/hero-terapia-pareja.jpg';
import heroTerapiaJovenes from '../../assets/img/hero-terapia-jovenes.jpg';
import heroTerapiaFamiliar from '../../assets/img/hero-terapia-familiar.jpg';
import heroTerapiaAdultomayor from '../../assets/img/hero-terapia-adultomayor.jpg';
import heroTerapiaPadres from '../../assets/img/hero-terapia-padres.jpg';
import heroTerapiaGruposapoyo from '../../assets/img/hero-terapia-gruposapoyo.jpg';
import heroTerapiaCoaching from '../../assets/img/hero-terapia-coaching.jpg';
import heroTerapiaDefault from '../../assets/img/hero-terapia-default.jpg';

// Description Modules
import Tindividual from "../../components/SpecialtyDescription/description/Tindividual";
import Tpareja from "../../components/SpecialtyDescription/description/Tpareja";
import Tjovenes from "../../components/SpecialtyDescription/description/Tjovenes";
import Tfamiliar from "../../components/SpecialtyDescription/description/Tfamiliar";
import Tadultomayor from "../../components/SpecialtyDescription/description/Tadultomayor";
import Tpadres from "../../components/SpecialtyDescription/description/Tpadres";
import Tgruposapoyo from "../../components/SpecialtyDescription/description/Tgruposapoyo";
import Tcoaching from "../../components/SpecialtyDescription/description/Tcoaching";
import Tdefault from "../../components/SpecialtyDescription/description/Tdefault";

export const specialityContent = ( area ) => {

  let hero;
  let description;

  switch ( area ) {

    case "t.individual":
      hero = heroTerapiaIndividual;
      description =  <Tindividual />;
      break;
    case "t.pareja":
      hero = heroTerapiaPareja;
      description = <Tpareja />;
      break;
    case "t.jovenes":
      hero = heroTerapiaJovenes;
      description = <Tjovenes />;
      break;
    case "t.familiar":
      hero = heroTerapiaFamiliar;
      description = <Tfamiliar />;
      break;
    case "t.adultomayor":
      hero = heroTerapiaAdultomayor;
      description = <Tadultomayor />;
      break;
    case "t.padres":
      hero = heroTerapiaPadres;
      description = <Tpadres />;
      break;
    case "t.gruposapoyo":
      hero = heroTerapiaGruposapoyo;
      description = <Tgruposapoyo />;
      break;
    case "t.coaching":
      hero = heroTerapiaCoaching;
      description = <Tcoaching />;
      break;
    default:
      hero = heroTerapiaDefault;
      description =  <Tdefault />;
  }

  return {
    "hero" : hero,
    "description" : description
  };
};