import React, { Component } from 'react';

export default class Tfamiliar extends Component {

  render() {
    return (
      <div style={{textAlign: 'justify'}}>
        {/*<h2 className="headline _uppercase">Terapia familiar Psicología</h2>*/}
        <p>En la terapia psicológica familiar se desarrollan temáticas diversas como: limites de convivencia, presupuesto familiar, comunicación asertiva, generación de vínculos saludables, respeto de los espacios de cada miembro, duelos, relación padres e hijos (a), relación de pareja, divorcio, decisiones que cambian la dinámica familiar, entre otras temáticas que atraviesan las familias en Costa Rica.</p>
        <p>Además se analiza desde la psicología, las etapas que atraviesan las familias en su ciclo y partir de este análisis se busca: favorecer la comunicación entre los miembros, generar espacios para conocer las necesidades de cada miembro, brindar a los padres de familia los recursos y habilidades que les ayuden en el crecimiento integral de sus hijos (as) y definir como será la convivencia en la familia y de pareja.</p>
      </div>
    )
  }
}
