import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import './SpecialtyContent.scss';
import specialty1 from '../../assets/img/specialty-1.jpg';
import specialty2 from '../../assets/img/specialty-2.jpg';
import specialty3 from '../../assets/img/specialty-3.jpg';
import specialty4 from '../../assets/img/specialty-4.jpg';
import specialty5 from '../../assets/img/specialty-5.jpg';
import specialty6 from '../../assets/img/specialty-6.jpg';
import specialty7 from '../../assets/img/specialty-7.jpg';
import specialty8 from '../../assets/img/specialty-8.jpg';

export default class SpecialtyContent extends Component {

  render() {
    return (
      <div className="specialty-content">

        <h2 className="_uppercase _margin-top-50 _margin-bottom-20">Especialidades</h2>

        <div className="row">
          <div className="col-md-4 _margin-bottom-40">

            <div className="specialty-block">

              <Link to="/speciality/t.individual" replace>
                <img src={specialty1} alt="Specialty"/>
              </Link>

              <h3 className="_uppercase _margin-top-20 _margin-bottom-10">Terapia individual Psicología</h3>
              <p>La terapia individual se basa en el encuentro entre el Psicólogo (terapeuta) y el paciente en un ambiente de aceptación, confidencialidad y apertura que permita la expresión de sus problemas y emociones.</p>
              <a className="button_color" href="/speciality/t.individual" target="_self">Leer más</a>
            </div>

          </div>
          <div className="col-md-4 _margin-bottom-40">

            <div className="specialty-block">
              <Link to="/speciality/t.pareja" replace>
                <img src={specialty2} alt="Specialty"/>
              </Link>
              <h3 className="_uppercase _margin-top-20 _margin-bottom-10">Terapia de pareja Psicología</h3>
              <p>Ayuda a las parejas en la comprensión y la tolerancia, a realizar cambios en las ideas y expectativas, irracionales y destructivas, a arreglar conflictos, mejorar la comunicación y promover relaciones armoniosas y duraderas.</p>
              <a className="button_color" href="/speciality/t.pareja" target="_self">Leer más</a>
            </div>

          </div>
          <div className="col-md-4 _margin-bottom-40">

            <div className="specialty-block">
              <Link to="/speciality/t.jovenes" replace>
                <img src={specialty3} alt="Specialty"/>
              </Link>
              <h3 className="_uppercase _margin-top-20 _margin-bottom-10">Terapia para niños y adolescentes</h3>
              <p>En la terapia con niños se trabaja con el o la menor, y con los encargados y familiares, dentro de las temáticas que más se consultan: manejo del enojo, manejo de límites y emociones, bullying, dificultades escolares y más.</p>
              <a className="button_color" href="/speciality/t.jovenes" target="_self">Leer más</a>
            </div>

          </div>

          <div className="col-md-4 _margin-bottom-40">

            <div className="specialty-block">
              <Link to="/speciality/t.familiar" replace>
                <img src={specialty4} alt="Specialty"/>
              </Link>
              <h3 className="_uppercase _margin-top-20 _margin-bottom-10">Terapia familiar Psicología</h3>
              <p>Se desarrollan temáticas diversas: límites de convivencia, presupuesto familiar, comunicación asertiva, generación de vínculos saludables, duelos, relación padres e hijos (as), relación de pareja, divorcio.</p>
              <a className="button_color" href="/speciality/t.familiar" target="_self">Leer más</a>
            </div>

          </div>
          <div className="col-md-4 _margin-bottom-40">
            <div className="specialty-block">
              <Link to="/speciality/t.adultomayor" replace>
                <img src={specialty5} alt="Specialty"/>
              </Link>
              <h3 className="_uppercase _margin-top-20 _margin-bottom-10">Terapia en el Adulto mayor Psicología</h3>
              <p>Nuestro objetivo en la terapia es el manejo de la autoestima, incentivar las habilidades sociales, manejo del tiempo libre y autocuidado para contribuir al mejoramiento en la calidad de vida y promover un funcionamiento integral y permitir una vejez placentera.</p>
              <a className="button_color" href="/speciality/t.adultomayor" target="_self">Leer más</a>
            </div>

          </div>
          <div className="col-md-4 _margin-bottom-40">
            <div className="specialty-block">
              <Link to="/speciality/t.padres" replace>
                <img src={specialty6} alt="Specialty"/>
              </Link>
              <h3 className="_uppercase _margin-top-20 _margin-bottom-10">Escuela para padres Psicología</h3>
              <p>El objetivo de estas escuelas es ayudar a los padres y madres en la crianza de los hijos y orientarlos en las diferentes etapas del desarrollo por las que atraviesan los niños (as), las cuales, en muchas ocasiones son motivo de angustia y temor para los padres. </p>
              <a className="button_color" href="/speciality/t.padres" target="_self">Leer más</a>
            </div>

          </div>
          <div className="col-md-4 _margin-bottom-40">
            <div className="specialty-block">
              <Link to="/speciality/t.gruposapoyo" replace>
                <img src={specialty7} alt="Specialty"/>
              </Link>
              <h3 className="_uppercase _margin-top-20 _margin-bottom-10">Grupos de apoyo Psicología</h3>
              <p>Los grupos de apoyo van dirigidos a personas que desean compartir y exponer en un lugar seguro, la situaciones y conflictos por la cuales atraviesan, para ayudarlos a resolver y encontrar salidas a dichas situaciones.</p>
              <a className="button_color" href="/speciality/t.gruposapoyo" target="_self">Leer más</a>
            </div>

          </div>
          <div className="col-md-4 _margin-bottom-40">
            <div className="specialty-block">
              <Link to="/speciality/t.coaching" replace>
                <img src={specialty8} alt="Specialty"/>
              </Link>
              <h3 className="_uppercase _margin-top-20 _margin-bottom-10">Coaching</h3>
              <p>El coaching consiste en acompañar, a una persona o a un grupo de ellas, con el fin de conseguir cumplir metas o desarrollar habilidades específicas, aumentar su rendimiento y mejora su calidad de vida.</p>
              <a className="button_color" href="/speciality/t.coaching" target="_self">Leer más</a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
