import React, { Component } from 'react';
import Slider from "react-slick";

// Home assets
import heroPsicologos2 from '../../assets/img/hero-psicologos-2.jpg';
import heroPsicologos3 from '../../assets/img/hero-terapia-padres.jpg';
import heroPsicologos4 from '../../assets/img/hero-terapia-adultomayor.jpg';
import heroPsicologos7 from '../../assets/img/hero-psicologos-3.jpg';

import './Hero.scss';
import { specialityContent } from '../../global/contentSelectors/specialitySelector';
import { doctorContent } from '../../global/contentSelectors/doctorsSelector';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClipboard} from "@fortawesome/free-solid-svg-icons";

export default class Hero extends Component {

  /**
   * heroSelector
   * @returns {*}
   */
  heroSelector = () => {

    let hero;
    const { heroSection, area, doctorId } = this.props;

    const homeHero = (
      <Slider {...Hero.CarouselSettings()}>
        {/*<div>*/}
        {/*  <img src={heroPsicologos1} alt="Clínica Naia. Psicología. Terapeutas."/>*/}
        {/*</div>*/}
        <div>
          <img src={heroPsicologos2} alt="Clínica Naia. Psicología. Terapeutas."/>
        </div>
        <div>
          <img src={heroPsicologos3} alt="Clínica Naia. Psicología. Terapeutas."/>
        </div>
        <div>
          <img src={heroPsicologos4} alt="Clínica Naia. Psicología. Terapeutas."/>
        </div>
        <div>
          <img src={heroPsicologos7} alt="Clínica Naia. Psicología. Terapeutas."/>
        </div>
      </Slider>
    );

    const specialtyHero = (
      <div>
        <img src={ specialityContent( area ).hero } alt="Clínica Naia. Psicología. Terapeutas."/>
      </div>
    );

    const doctorHero = (
      <div>
        <img src={ doctorContent( doctorId ).hero} alt="Clínica Naia. Psicología. Terapeutas."/>
      </div>
    );

    // Hero section
    if( heroSection === 'home' ){

      hero = homeHero;
    } else if ( heroSection === 'specialty' ) {

      hero = specialtyHero;
    } else if ( heroSection === 'doctor' ) {

      hero = doctorHero;
    }

    return hero;
  };

  heroContentSelector = () => {

    let content;
    const { heroSection, area, doctorId } = this.props;

    //
    const homeContent_0 = (
      <div className="home-hero-content">
        <div className="t_box">
          <h2 className="_uppercase">Naia</h2>
          <h3>Clínica de psicología y psicoterapia</h3>

          <a className="button_color" href="/doctor/"> <FontAwesomeIcon icon={faClipboard} className="color_white _margin-right-10"/> Nuestros Terapeutas</a>
          <a className="button_color" href="/speciality/"> <FontAwesomeIcon icon={faClipboard} className="color_white _margin-right-10"/>Especialidades</a>
          <a className="button_color" href="/home/quienessomos"> <FontAwesomeIcon icon={faClipboard} className="color_white _margin-right-10"/>Quienes Somos</a>
        </div>
      </div>
    );

    //*****************************************************************************
    // Specialty
    //*****************************************************************************

    const specialtyContent_individual = (
      <div className="home-hero-content">
        <div className="t_box">
          <h2>TERAPIA INDIVIDUAL PSICOLOGÍA</h2>
        </div>
      </div>
    );

    const specialtyContent_pareja = (
      <div className="home-hero-content">
        <div className="t_box">
          <h2>TERAPIA DE PAREJA PSICOLOGÍA</h2>
        </div>
      </div>
    );

    const specialtyContent_jovenes = (
      <div className="home-hero-content">
        <div className="t_box">
          <h2>TERAPIA PARA NIÑOS Y ADOLESCENTES PSICOLOGÍA</h2>
        </div>
      </div>
    );

    const specialtyContent_familiar = (
      <div className="home-hero-content">
        <div className="t_box">
          <h2>TERAPIA FAMILIAR PSICOLOGÍA</h2>
        </div>
      </div>
    );

    const specialtyContent_adultomayor = (
      <div className="home-hero-content">
        <div className="t_box">
          <h2>TERAPIA EN EL ADULTO MAYOR PSICOLOGÍA</h2>
        </div>
      </div>
    );

    const specialtyContent_padres = (
      <div className="home-hero-content">
        <div className="t_box">
          <h2>ESCUELA PARA PADRES PSICOLOGÍA</h2>
        </div>
      </div>
    );

    const specialtyContent_gruposapoyo = (
      <div className="home-hero-content">
        <div className="t_box">
          <h2>GRUPOS DE APOYO PSICOLOGÍA</h2>
        </div>
      </div>
    );

    const specialtyContent_coaching = (
      <div className="home-hero-content">
        <div className="t_box">
          <h2>COACHING</h2>
        </div>
      </div>
    );

    //*****************************************************************************
    //*****************************************************************************
    const marisolContent = (
      <div className="doctor-hero-content">
        <div className="doctor-info">
          <h2 className="_margin-bottom-10">Dra. Marisol Montero</h2>
          <p className="_text-shadow">La Dra. Montero, se interesa por orientar principalmente el desarrollo de las personas.</p>
        </div>
      </div>
    );

    const guillermoContent = (
      <div className="doctor-hero-content">
        <div className="doctor-info">
          <h2 className="_margin-bottom-10">Dr. Guillermo Pacheco</h2>
          <p className="_text-shadow">Las personas tienen ese potencial de lograr<br/>cualquier cosa que se propongan.</p>
        </div>
      </div>
    );

   const fiorellaContent = (
    <div className="doctor-hero-content">
     <div className="doctor-info">
      <h2 className="_margin-bottom-10">Dra. Fiorella Hidalgo Solano</h2>
      <p className="_text-shadow">En la terapia psicológica, no solo se podrán ver temas como la felicidad, sino también temas como, habilidades para la vida</p>
     </div>
    </div>
   );
    //*****************************************************************************
    //*****************************************************************************


    // Hero section
    if( heroSection === 'home' ){

      content = homeContent_0

    } else if ( heroSection === 'specialty' ) {

      if ( area === "t.individual" ){

        content = specialtyContent_individual
      }
      else if ( area === "t.pareja" ){

        content = specialtyContent_pareja;
      }
      else if ( area === "t.jovenes" ){

        content = specialtyContent_jovenes
      }
      else if ( area === "t.familiar" ){

        content = specialtyContent_familiar
      }
      else if ( area === "t.adultomayor" ){

        content = specialtyContent_adultomayor
      }
      else if ( area === "t.padres" ){

        content = specialtyContent_padres
      }
      else if ( area === "t.gruposapoyo" ){

        content = specialtyContent_gruposapoyo
      }
      else if ( area === "t.coaching" ){

        content = specialtyContent_coaching
      }

    } else if ( heroSection === 'doctor' ) {

      if( doctorId === "d.guillermo" ) {
        content = guillermoContent;
      }
      else if ( doctorId === "d.marisol" ) {
        content = marisolContent;
      }
      else if ( doctorId === "d.fiorella" ) {
        content = fiorellaContent;
      }
    }

    return content;

  };

  /**
   * CarouselSettings
   * @returns {{dots: boolean, infinite: boolean, pauseOnHover: boolean, slidesToScroll: number, slidesToShow: number, speed: number, autoplay: boolean, autoplaySpeed: number}}
   * @constructor
   */
  static CarouselSettings() {
    return {
      dots: false,
      infinite: true,
      speed: 400,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      pauseOnHover: false
    };
  }

  render() {

    return (
      <div className="hero">

        { this.heroSelector() }

        <div className="content over-text _float_center">
          <div className="content_margin">

            { this.heroContentSelector() }

          </div>
        </div>

      </div>
    )
  }
}
