//import anime from 'animejs';
import $ from 'jquery';

/**
 * reload the page
 */
export const reloadThePage = function (){
  setTimeout(function () {
    window.location.reload();
  }, 100);
};

/**
 * moveThePageTop
 */
export const moveThePageTop = function ( anim ) {

  setTimeout(function () {
    window.scrollTo(0,0);
  }, 100);
};

export const moveThePageTo = ( deep ) => {

  let $node = $('#'+deep);

  // flaq
  if( $node.length === 0 ) return;

  // wait for dom
  setTimeout(function () {

    $('html, body').animate({
      scrollTop: $node.offset().top - 20
    }, 500);

  }, 500);



  // const container = document.querySelector('#'+deep);
  // console.log('#'+deep);
  // console.log( container );
  //
  // if ( !container ){
  //   return;
  // }

  // console.log( container.offsetTop );
  //
  // setTimeout(function () {
  //
  //   anime.remove("html, body");
  //   anime({
  //     targets: "html, body",
  //     easing: 'linear',
  //     scrollTop: container.offsetTop
  //   });
  // }, 100);


};