import React, {Component} from 'react';

import './QuoteNote.scss';
import quoteDoctorGuillermo from '../../assets/img/quote-doctor-guillermo.jpg';
import quoteDoctorMarisol from '../../assets/img/hero-doctor-marisol.jpg';
import quoteDoctorFiorella from '../../assets/img/quote-doctor-fiorella.jpg';
import {Parallax} from "react-parallax";

export default class QuoteNote extends Component {

  getQuote = () => {

    let quoteMarisol = (
      <Parallax
        bgImage={quoteDoctorMarisol}
        bgImageAlt="Dra. Marisol Carvajal"
        strength={500}

      >

        <div className="row" style={{padding: "100px 0"}}>
          <div className="col-md-6"></div>
          <div className="col-md-6 align-self-center">

            <div className="info-block">
              <div className="quote">
                “ En nuestros niños, enseñar a desarrollar la inteligencia emocional es primordial antes que leer y
                sumar
                ”
              </div>

              <div className="doctor-info">
                <h2>Dra. Marisol Montero Carvajal</h2>
                <p>Psicología clínica en niños, adolescentes y adultos<br />terapia individual y pareja.</p>
              </div>
            </div>

          </div>
        </div>

      </Parallax>
    );

    let quoteGuillermo = (
      <Parallax
        blur={0}
        bgImage={quoteDoctorGuillermo}
        bgImageAlt="Dr. Guillermo Pacheco Campos"
        strength={500}

      >

        <div className="row" style={{padding: "100px 0"}}>
          <div className="col-md-6"></div>
          <div className="col-md-6 align-self-center">

            <div className="info-block">
              <div className="quote">
                “ Las personas tienen ese potencial de lograr cualquier cosa que se propongan, de sobreponerse a
                cualquier
                circunstancia que los agobia, y emprender ese viaje hacia el éxito, hacia la felicidad ”
              </div>

              <div className="doctor-info">
                <h2>Dr. Guillermo Pacheco Campos</h2>
                <p>Psicología clínica en adultos, terapia individual y pareja,<br />couching personal.</p>
              </div>
            </div>

          </div>
        </div>

      </Parallax>
    );

    // let quoteFiorella = (
    //   <Parallax
    //     blur={0}
    //     bgImage={quoteDoctorFiorella}
    //     bgImageAlt="Dra. Fiorella Hidalgo Solano"
    //     strength={500}
    //   >
    //
    //     <div className="row" style={{padding: "100px 0"}}>
    //       <div className="col-md-6"></div>
    //       <div className="col-md-6 align-self-center">
    //
    //         <div className="info-block">
    //           <div className="quote">
    //             “ El amor la responsabilidad y los límites deben ser la base de la crianza para nuestros niños. De esta manera formaremos  adultos emocionalmente equilibrados y estables. ”
    //           </div>
    //
    //           <div className="doctor-info">
    //             <h2>Dra. Fiorella Hidalgo Solano</h2>
    //             <p>Psicología clínica en niños, adolescentes y adultos<br />terapia individual y pareja.</p>
    //           </div>
    //         </div>
    //
    //       </div>
    //     </div>
    //
    //   </Parallax>
    // );


    var quotes = [quoteMarisol, quoteGuillermo]; //[quoteFiorella, quoteMarisol, quoteGuillermo, quoteXiomara];
    var min = 0;
    var max = quotes.length;
    var random = Math.floor(Math.random() * (+max - +min)) + +min;

    console.log(random)
    return quotes[random];
  };


  render() {
    return (
      <div className="quote-note module-layout">
        <div className="content">
          <div className="content_margin _remove-margin-sm-left _remove-margin-sm-right">

            {this.getQuote()}

          </div>
        </div>
      </div>
    )
  }
}
