import React, { Component } from 'react';

import ModalWindow from '../ModalWindow/ModalWindow';
import SendConsult from '../forms/SendConsult/SendConsult';
import { specialityContent } from '../../global/contentSelectors/specialitySelector';

export default class SpecialtyDescription extends Component {

  render() {

    const { area } = this.props;

    // MODAL FORM
    const modalcontent = (
      <SendConsult area={ area } />
    );

    return (
      <div className="specialty-description module-layout">

        {/* title and description */}
        { specialityContent( area ).description }

        <div style={{textAlign:"right"}} className="_margin-top-80">
          <button type="button" className="button_color" onClick={ () => this.clickChild() }>Escríbenos para hacer una consulta</button>
        </div>

        {/* MODAL : INIT */}
        <ModalWindow modalcontent={ modalcontent } setClick={ click => this.clickChild = click }/>
        {/* MODAL : END */}

      </div>
    )
  }
}
