import React from "react";

// Hero assets
import heroGuillermo from '../../assets/img/hero-doctor-guillermo.jpg';
import heroMarisol from '../../assets/img/hero-doctor-marisol.jpg';
import heroFiorella from '../../assets/img/hero-doctor-fiorella.jpg';
//import heroXiomara from '../../assets/img/hero-doctor-xiomara.jpg';
import heroDefault from '../../assets/img/hero-doctor-default.jpg';

// Description Modules
import Dguillermo from '../../components/DoctorDescription/description/Dguillermo';
import Dmarisol from '../../components/DoctorDescription/description/Dmarisol';
import Dfiorella from '../../components/DoctorDescription/description/Dfiorella';
//import DxiomaraNull from '../../components/DoctorDescription/description/Dxiomara-null';
import Ddefault from '../../components/DoctorDescription/description/Ddefault';

export const doctorContent = ( doctorId ) => {

  let hero;
  let description;

  switch ( doctorId ) {

    case "d.guillermo":
      hero =  heroGuillermo;
      description = <Dguillermo />;
      break;
    case "d.marisol":
      hero = heroMarisol;
      description = <Dmarisol />;
      break;
    case "d.fiorella":
      hero = heroFiorella;
      description = <Dfiorella />;
      break;
    // case "d.xiomara":
    //   hero = heroXiomara;
    //   description = <DxiomaraNull />;
    //   break;
    default:
      hero = heroDefault;
      description = <Ddefault />;
  }

  return {
    "hero" : hero,
    "description" : description
  };
};
