import React, { Component } from 'react';

export default class Ddefault extends Component {

  render() {
    return (
      <div>
        <h2 className="headline _uppercase">Especialista en todo</h2>
        <p>La terapia individual se da en un ambiente de consentimiento, apertura y confidencialidad, donde cada paciente encuentra un lugar para expresar y trabajar sus problemas y emociones. El objetivo es ayudar al paciente a obtener un mayor conocimiento de si mismo, así como de sus conductas, pensamientos y emociones que dan origen a su motivo de consulta.</p>
        <p>En esta terapia el paciente aprende a manejar sus emociones y logrará mejorar en sus diferentes ámbitos, como la toma de decisiones, vínculos sanos y una autoimagen que le permite relacionarse de manera más asertiva con su entorno.</p>
        <p>Para ello se aplica tanto la terapia cognitivo conductual como la psicodinámica.</p>
      </div>
    )
  }
}
