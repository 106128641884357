import React, { Component } from 'react';

// modules
import QuoteNote from "../../components/QuoteNote/QuoteNote";
import SpecialtyHeader from "../../components/SpecialtyHeader/SpecialtyHeader";
import SpecialtyContent from "../../components/SpecialtyContent/SpecialtyContent";
import BandAnnounce from "../../components/BandAnnounce/BandAnnounce";
import MeetOurDoctors from "../../components/MeetOurDoctors/MeetOurDoctors";
import HeaderFooter from "../../templates/HeaderFooter/HeaderFooter";
import DoctorDescription from "../../components/DoctorDescription/DoctorDescription";


export default class Doctor extends Component {

  render() {

    // URL params
    const { match: { params } } = this.props;

    // Default description content
    let defaultDoctor = 'd.guillermo';
    let doctor = params.doctor || defaultDoctor;

    let pageContent = (
      <React.Fragment>

        <div className="content">
          <div className="content_margin">
            <DoctorDescription doctorId={doctor}/>
          </div>
        </div>

        <BandAnnounce/>


        <div className="content">
          <div className="content_margin">

            <SpecialtyHeader includeTitle='true'/>
            <SpecialtyContent/>

          </div>
        </div>

        <QuoteNote/>

        <div className="content">

          <MeetOurDoctors/>

        </div>

        {/*<GoogleMap />*/}

      </React.Fragment>
    );

    return (
      <div>
        <HeaderFooter pageId='doctor' heroSection='doctor' pageContent={ pageContent } doctorId={ doctor }   />
      </div>
    )
  }
}
