import React, { Component } from 'react';
import './NotFound.scss';

export default class NotFound extends Component {
  render() {
    return (
      <div className="notfound">
        Page not found
      </div>
    )
  }
}
