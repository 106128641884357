import React, {Component} from 'react';

import Hero from "../Hero/Hero";
import LogoNous from "../LogoNous/LogoNous";
import BandPhoneEmail from "../BandPhoneEmail/BandPhoneEmail";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";

import './Header.scss';

export default class Header extends Component {

  render() {

    const {heroSection, area, doctorId} = this.props;

    return (
      <header className="header">

        <BandPhoneEmail/>

        <div className="h-transparent _box-shadow">
          <div className="content">
            <div className="content_margin logo-nous content-logo">
              <a href="/" target="_self" style={{textDecoration:'none'}}>
               <LogoNous />
               {/*<h1 className="_text-shadow">Naia Psicólogos</h1>*/}
              </a>

              <a href="/" target="_self" style={{textDecoration:'none'}} className='al-left'>
                <FontAwesomeIcon icon={faHome} className="color_white _margin-left-20 _margin-right-10"/> <span className="color_white">Inicio</span>
              </a>
            </div>
          </div>
        </div>

        {/* INIT:HERO */}
        <Hero heroSection={heroSection} area={area} doctorId={doctorId}/>
        {/* END:HERO */}

      </header>
    )
  }
}
