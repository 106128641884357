import React, {Component} from 'react';
import { Link } from 'react-router-dom'
import {faAddressBook, faMobileAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import './MeetOurDoctors.scss';
//import Dxiomara from '../../assets/img/d.xiomara.jpg';
import Dguillermo from '../../assets/img/d.guillermo.jpg';
import Dmarisol from '../../assets/img/d.marisol.jpg';
import Dfiorella from '../../assets/img/d.fiorella.jpg';


import ModalWindow from '../ModalWindow/ModalWindow';
import SendToDoctor from "../forms/SendToDoctor/SendToDoctor";


export default class MeetOurDoctors extends Component {

  constructor(props){
    super(props);

    this.state = {
      doctorEmail:''
    }
  }

  render() {

    const { doctorEmail } = this.state;

    // MODAL FORM
    const modalcontent = (
      <SendToDoctor doctorEmail = {doctorEmail} />
    );

    return (
      <div className="meet-our-doctors module-layout">

        <div className="content_margin">
          <h2 className="headline _uppercase">Conozca a nuestros Psicoterapeutas</h2>
          <p>Somos un equipo de trabajo de profesionales en Psicología altamente comprometidos con nuestros pacientes, nuestro entorno y con sentido de responsabilidad social, promoviendo estilos de vida saludables orientados a desarrollar la potencialidad de las personas.</p>
          <p>Buscamos el bienestar de nuestros pacientes por medio del  conocimiento, utilizando las técnicas más modernas de psicoterapia, con altos niveles de responsabilidad, ética, excelencia e integridad.</p>
        </div>

        <div className="row just-center">

          <div className="col-md-4 _col-paddings-sm-remove block">
            <div className="card-block">

              <Link to='/doctor/d.marisol' replace>
                <img src={Dmarisol} alt="Doctor"/>
              </Link>

              <div className="card-info">
                <h3>Dra. Marisol Montero</h3>
                <p>Psicología clínica en niños, adolescentes y adultos terapia individual y pareja.</p>

                <ul className="ul-doctor-info">
                  <li>
                    <button onClick={ () => {

                      this.setState({
                        doctorEmail:'mmontero@clinicanaia.com'
                      });

                      this.clickChild();
                    }
                    }>mmontero@clinicanaia.com</button>
                  </li>

                  <li className="p-inline">
                    <p><FontAwesomeIcon icon={faMobileAlt} className="color_white _margin-right-10"/>Cel 8326-9782</p>
                    <p><FontAwesomeIcon icon={faMobileAlt} className="color_white _margin-right-10"/>Ofic 4040-0185</p>
                  </li>

                </ul>
              </div>
            </div>
          </div>

          <div className="col-md-4 _col-paddings-sm-remove block">
            <div className="card-block">

              <Link to='/doctor/d.guillermo' replace>
                <img src={Dguillermo} alt="Doctor"/>
              </Link>

              <div className="card-info">
                <h3>Dr. Guillermo Pacheco</h3>
                <p>Psicología clínica en adultos, terapia individual y pareja, couching personal.</p>

                <ul className="ul-doctor-info">
                  <li>
                    <button onClick={ () => {

                      this.setState({
                        doctorEmail:'gpacheco@clinicanaia.com'
                      });

                      this.clickChild();
                    }
                    }>gpacheco@clinicanaia.com</button>
                  </li>

                  <li className="p-inline">

                    <p><FontAwesomeIcon icon={faMobileAlt} className="color_white _margin-right-10"/>Cel 8483-9955</p>
                    <p><FontAwesomeIcon icon={faMobileAlt} className="color_white _margin-right-10"/>Ofic 4040-0185</p>

                  </li>

                  <li className="p-inline">

                    <a rel="noopener noreferrer" href="https://medismart.hulilabs.com/es/doctor/jose-guillermo-pacheco-campos?ref=sb" target="_blank"><FontAwesomeIcon icon={faAddressBook} className="color_white _margin-right-10"/> Agenda con Medismart</a>
                    <p>o llame al <FontAwesomeIcon icon={faMobileAlt} className="color_white _margin-right-10 _margin-left-10"/>2528-5400</p>

                  </li>

                </ul>
              </div>
            </div>
          </div>

          {/*<div className="col-md-4 _col-paddings-sm-remove block">*/}
          {/*  <div className="card-block">*/}

          {/*    <Link to='/doctor/d.fiorella' replace>*/}
          {/*      <img src={Dfiorella} alt="Doctor"/>*/}
          {/*    </Link>*/}

          {/*    <div className="card-info">*/}
          {/*      <h3>Dra. Fiorella Hidalgo Solano</h3>*/}
          {/*      <p>Psicología clínica en niños, adolescentes y adultos terapia individual y pareja.</p>*/}

          {/*      <ul className="ul-doctor-info">*/}
          {/*        <li>*/}
          {/*          <button onClick={ () => {*/}

          {/*            this.setState({*/}
          {/*              doctorEmail:'fhidalgo@clinicanaia.com '*/}
          {/*            });*/}

          {/*            this.clickChild();*/}
          {/*          }*/}
          {/*          }>fhidalgo@clinicanaia.com</button>*/}
          {/*        </li>*/}

          {/*        <li className="p-inline">*/}
          {/*          <p><FontAwesomeIcon icon={faMobileAlt} className="color_white _margin-right-10"/>Cel 7107-8714</p>*/}
          {/*          <p><FontAwesomeIcon icon={faMobileAlt} className="color_white _margin-right-10"/>Ofic 4040-0185</p>*/}
          {/*        </li>*/}

          {/*      </ul>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}

        </div>

        {/* MODAL : INIT */}
        <ModalWindow modalcontent={ modalcontent } setClick={ click => this.clickChild = click }/>
        {/* MODAL : END */}
      </div>
    )
  }
}
