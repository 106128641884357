import React, {Component} from 'react';

// modules
import SpecialtyHeader from "../../components/SpecialtyHeader/SpecialtyHeader";
import SpecialtyContent from "../../components/SpecialtyContent/SpecialtyContent";
import QuoteNote from "../../components/QuoteNote/QuoteNote";
import BandAnnounce from "../../components/BandAnnounce/BandAnnounce";
import MeetOurDoctors from "../../components/MeetOurDoctors/MeetOurDoctors";
import HeaderFooter from "../../templates/HeaderFooter/HeaderFooter";
import SpecialtyDescription from "../../components/SpecialtyDescription/SpecialtyDescription";

export default class Specialty extends Component {

  render() {

    // URL params
    const { match: { params } } = this.props;

    // Default description content
    let defaultArea = 't.individual';
    let area = params.area || defaultArea;

    //
    let pageContent = (
      <React.Fragment>

        <div className="content">
          <div className="content_margin">
            <SpecialtyDescription area={area}/>
          </div>
        </div>

        <div className="content">
          <div className="content_margin">

            <SpecialtyHeader includeTitle='true'/>
            <SpecialtyContent/>

          </div>
        </div>

        <QuoteNote/>

        <BandAnnounce/>

        <div className="content">

          <MeetOurDoctors/>

        </div>

        {/*<GoogleMap />*/}

      </React.Fragment>
    );

    return (
      <div>
        <HeaderFooter pageId='specialty' heroSection='specialty' pageContent={pageContent} area={area} />
      </div>
    )
  }
}
