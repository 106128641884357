import React, { Component } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMobileAlt} from "@fortawesome/free-solid-svg-icons";

import ModalWindow from "../../ModalWindow/ModalWindow";
import SendToDoctor from "../../forms/SendToDoctor/SendToDoctor";

export default class Dfiorella extends Component {

  constructor(props){
    super(props);

    this.state = {
      doctorEmail:''
    }
  }

  render() {

    const { doctorEmail } = this.state;

    // MODAL FORM
    const modalcontent = (
      <SendToDoctor doctorEmail = { doctorEmail } />
    );

    return (
      <div style={{ textAlign: 'justify' }}>
        <h2 className="headline _uppercase"> Psicología clínica en niños, adolescentes y adultos terapia individual y pareja. </h2>
        <p>El ser humano desde la infancia posee miles de recursos y herramientas que si las sabemos implementar podremos llegar a ser felices en cada etapa de nuestra vida. Si nosotros los adultos les brindamos estas herramientas a los niños, niñas y adolescentes estaremos contribuyendo a desarrollar adultos emocionalmente estables, con una alta inteligencia emocional para afrontar y sobrellevar todos aquellos acontecimientos u obstáculos que se les presenten.</p>
        <p>En la terapia psicológica, no solo se podrán ver temas como la felicidad, sino también temas como, habilidades para la vida, desarrollo de competencias, autoconcepto, autopercepción, autoestima, crecimiento personal o temas como la rebeldía, agresión, problemas emocionales o escolares, duelo, depresión entre muchos otros temas que se podrán trabajar</p>
        <div className="card-info">
          <h3>Contácto</h3>

          <ul className="ul-doctor-info">
            <li>
              <button onClick={ () => {

                this.setState({
                  doctorEmail:'fhidalgo@clinicanaia.com'
                });

                this.clickChild();
              }
              }>fhidalgo@clinicanaia.com</button>
            </li>

            <li className="p-inline">

              <p><FontAwesomeIcon icon={faMobileAlt} className="color_white _margin-right-10"/>Cel 7107-8714</p>
              <p><FontAwesomeIcon icon={faMobileAlt} className="color_white _margin-right-10"/>Ofic 4040-0185</p>

            </li>

          </ul>
        </div>

        {/* MODAL : INIT */}
        <ModalWindow modalcontent={ modalcontent } setClick={ click => this.clickChild = click }/>
        {/* MODAL : END */}

      </div>
    )
  }
}
