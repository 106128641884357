import React, { Component } from 'react';

export default class Tgruposapoyo extends Component {

  render() {
    return (
      <div style={{textAlign: 'justify'}}>
        {/*<h2 className="headline _uppercase">Grupos de apoyo Psicología</h2>*/}
        <p>Los grupos de apoyo van dirigidos a personas que desean compartir y exponer en un lugar seguro, la situaciones y conflictos por la cuales atraviesan.</p>
        <p>En los grupos de apoyo  se comparten vivencias y se intercambian ideas, el rol del terapeuta es orientar a los participantes para ayudarlos a resolver y encontrar salidas a dichas situaciones.</p>
      </div>
    )
  }
}
