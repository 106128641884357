import React, { Component } from 'react';

import '../../assets/scss/_module-layout.scss';
import './SpecialtyHeader.scss';

export default class SpecialtyHeader extends Component {
  render() {

    const { includeTitle } = this.props;

    return (
      <div className='specialty-header module-layout'>

        { includeTitle ?
          <h2 className="headline _uppercase">Naia clínica psicológica</h2> : ''
        }

        <p>Tenemos más de 10 años de experiencia, apoyando a las personas mediante terapia individual, de pareja y familiar, con el fin de empoderarlas para satisfacer sus necesidades emocionales y espirituales.</p>
        <p>Contamos con un equipo de profesionales formados en Costa Rica que compartimos una misma pasión: el crecimiento personal y el de nuestros pacientes, promoviendo el desarrollo de habilidades individuales y sociales para ayudarlos a tomar el control de sus emociones.</p>
      </div>
    )
  }
}
