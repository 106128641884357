import React, { Component } from 'react';

export default class Tadultomayor extends Component {

  render() {
    return (
      <div style={{textAlign: 'justify'}}>
        {/*<h2 className="headline _uppercase">Terapia en el Adulto mayor Psicología</h2>*/}
        <p>Según la Organización Mundial de la Salud, la adultez mayor es una etapa del desarrollo del ser humano y se caracteriza por ser un periodo de grandes cambios en todas las áreas de funcionamiento.</p>
        <p>La Teoría del Desarrollo Psicosocial de Erik Erikson nos dice que los adultos mayores necesitan contemplar su vida anterior y sentir una sensación de plenitud.</p>
        <p>El éxito en esta etapa del desarrollo conduce a sentimientos de sabiduría, mientras que los resultados de fracaso producen pesar, amargura y desesperación.</p>
        <p>En Costa Rica se ha encontrado que las variables psicosociales que más afectan al adulto mayor son:  post jubilación, calidad de vida, proyecto de vida y depresión.</p>
        <p>Nuestro objetivo en la terapia es el manejo de la autoestima, incentivar las habilidades sociales, manejo del tiempo libre y autocuidado para contribuir al mejoramiento en la calidad de vida y promover un funcionamiento integral y permitir una vejez placentera.</p>
      </div>
    )
  }
}
