import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faMobileAlt} from "@fortawesome/free-solid-svg-icons";

import './BandPhoneEmail.scss';

import ModalWindow from '../ModalWindow/ModalWindow';
import SendContact from '../forms/SendContact/SendContact';

export default class BandPhoneEmail extends Component {

  constructor(props) {
    super(props);

    this.state = {}
  }

  render() {

    const { isBottom } = this.props;

    // MODAL FORM
    const modalcontent = (
      <SendContact />
    );

    return (
      <div className="band-phone-email">
        <div className="content">
          <div className={'content_margin' + (isBottom ? ' space-between' : '')}>

            <div>
              <p style={{marginButton:'10px'}}><FontAwesomeIcon icon={faMobileAlt} className="color_white _margin-right-10"/>506 4040-0185</p>

              <p>
                <FontAwesomeIcon icon={faEnvelope} className="color_white _margin-left-20 _margin-right-10"/>
                <button className="button_over" onClick={ () => this.clickChild() }>contactenos@clinicanaia.com</button>
              </p>

            </div>

            { isBottom ? (

              <div>
                <p>© 2019. <a className="color_white" href="http://clinicanaia.com/">Clínica Psicológica Naia.</a></p>
              </div>

            ) : false }

          </div>
        </div>

        {/* MODAL : INIT */}
        <ModalWindow modalcontent={ modalcontent } setClick={ click => this.clickChild = click }/>
        {/* MODAL : END */}
      </div>
    )
  }
}
