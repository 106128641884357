import React, { Component } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAddressBook, faMobileAlt} from "@fortawesome/free-solid-svg-icons";

import ModalWindow from "../../ModalWindow/ModalWindow";
import SendToDoctor from "../../forms/SendToDoctor/SendToDoctor";

export default class Dguillermo extends Component {

  constructor(props){
    super(props);

    this.state = {
      doctorEmail:''
    }
  }

  render() {

    const { doctorEmail } = this.state;

    // MODAL FORM
    const modalcontent = (
      <SendToDoctor doctorEmail = {doctorEmail} />
    );

    return (
      <div style={{textAlign: 'justify'}}>
        <h2 className="headline _uppercase">Psicología clínica en adultos, terapia individual y pareja, couching personal.</h2>
        <p>En la Clínica Psicológica Naia Costa Rica nos interesa su salud mental, es por eso que nos dedicamos a brindar la mejor atención psicológica a las personas. </p>
        <p>Mediante la comunicación verbal y no verbal, técnicas de asociación libre, escucha activa, pruebas psicológicas, y sobre todo empatía, realizamos un diagnóstico psicológico, basados en el funcionamiento mental y emocional de la persona.</p>
        <p>Dicho diagnóstico es la base para la construcción de una estrategia integral que nos permita inferir en los comportamientos, y acompañarlo a buscar su propio camino, para mejorar su calidad de vida, lograr sus metas y desarrollar su máximo potencial, basados en el respeto a los deseos del paciente.</p>

        <div className="card-info">
          <h3>Contácto</h3>

          <ul className="ul-doctor-info">
            <li>
              <button onClick={ () => {

                this.setState({
                  doctorEmail:'gpacheco@clinicanaia.com'
                });

                this.clickChild();
              }
              }>gpacheco@clinicanaia.com</button>
            </li>

            <li className="p-inline">

              <p><FontAwesomeIcon icon={faMobileAlt} className="color_white _margin-right-10"/>Cel 8483-9955</p>
              <p><FontAwesomeIcon icon={faMobileAlt} className="color_white _margin-right-10"/>Ofic 4040-0185</p>

            </li>

            <li className="p-inline">

              <a rel="noopener noreferrer" href="https://medismart.hulilabs.com/es/doctor/jose-guillermo-pacheco-campos?ref=sb" target="_blank"><FontAwesomeIcon icon={faAddressBook} className="color_white _margin-right-10"/> Agenda con Medismart</a><br/>
              <p>o llame al <FontAwesomeIcon icon={faMobileAlt} className="color_white _margin-right-10 _margin-left-10"/>2528-5400</p>

            </li>

          </ul>
        </div>

        {/* MODAL : INIT */}
        <ModalWindow modalcontent={ modalcontent } setClick={ click => this.clickChild = click }/>
        {/* MODAL : END */}

      </div>
    )
  }
}
