import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

// pages
import Home from "./pages/Home/Home";
import NotFound from "./pages/NotFound/NotFound";
import Doctor from "./pages/Doctor/Doctor";
import Specialty from "./pages/Specialty/Specialty";

class App extends Component {
  render() {
    return (
        <Switch>
          <Route exact path ={"/"} component={Home} />
          <Route path ={"/home/:deep"} component={Home} />

          <Route exact path="/speciality" component={Specialty} />
          <Route path="/speciality/:area" component={Specialty} />

          <Route exact path={"/doctor"} component={Doctor} />
          <Route path={"/doctor/:doctor"} component={Doctor} />

          <Route component={NotFound} />
        </Switch>
    );
  }
}

export default App;
