import React, { Component } from 'react';

export default class Tindividual extends Component {

  render() {
    return (
      <div style={{textAlign: 'justify'}}>
        {/*<h2 className="headline _uppercase">Terapia individual Psicología</h2>*/}
        <p>La terapia individual se basa en el encuentro entre el Psicólogo (terapeuta) y el paciente en un ambiente de aceptación, confidencialidad y apertura que permita la expresión de sus problemas y emociones.</p>
        <p>Mediante el uso de técnicas psicológicas el terapeuta facilita un espacio, que le permite al paciente desarrollar el autoconocimiento y la comprensión profunda de sí mismo.</p>
        <p>Se incentiva al paciente a descubrir sus posibilidades y enfrentarse a sus problemas con mayor confianza y seguridad, a realizar cambios y tomar decisiones sobre su vida que le permitan vivir más plenamente.</p>
        <p>Normalmente nuestra mente se satura con creencias, ideas, pensamientos. La terapia individual promueve pasar del pensamiento a la acción, de las palabras a los hechos.</p>
      </div>
    )
  }
}
