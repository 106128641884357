import React, { Component } from 'react';

export default class Tpareja extends Component {

  render() {
    return (
      <div style={{textAlign: 'justify'}}>
        {/*<h2 className="headline _uppercase">Terapia de pareja Psicología</h2>*/}
        <p>Son varias las situaciones por las cuales las parejas deciden acudir a terapia en Costa Rica.</p>
        <p>Problemas sexuales, falta de comunicación, frustración porque la relación no es como se esperaba, insatisfacción con la relación, llegada de los hijos, ira, celos o infidelidad.</p>
        <p>En algunas parejas el proceso de convivencia es complicado, tienen que ir adaptándose a los cambios, utilizando los recursos psicológicos de los que disponen para ir manejando los conflictos.</p>
        <p>Sin embargo cuando los recursos propios fallan, van surgiendo problemas que generan malestar y afectan el estado emocional de cada individuo, aumentado la insatisfacción dentro de la relación.</p>
        <p>Esa insatisfacción puede mantenerse en el tiempo, deteriorar la relación cada vez más hasta llegar a romperla.</p>
        <p>La terapia de pareja en psicología, enseña a las personas a prevenir conflictos a comunicarse de manera efectiva, entrenamiento en resolución de problemas, aumento del intercambio de conductas positivas desde el “amor”, además ayuda a entender cómo los pensamientos influyen en las emociones.</p>
        <p>Ayuda a las parejas en la comprensión y la tolerancia, a realizar cambios en las ideas y expectativas, irracionales y destructivas.</p>
        <p>En las parejas que mantienen buenas relaciones, también se utiliza la terapia psicológica, para que aprendan a comunicarse mejor, prevenir futuros conflictos y promover relaciones más armoniosas y duraderas, fortalecer sus vínculos y conocerse mejor el uno al otro.</p>
      </div>
    )
  }
}
